<template>
  <div class="wrapper">

    <!-- Alert Component -->
    <CAlert
        color="danger"
        :show.sync="alertDanger"
        closeButton
    >
      Acción No Realizada.
      <CProgress
        color="info"
        :max="dismissSecs"
        :value="alertDanger"
        height="4px"
      />
    </CAlert>

    <CAlert
        color="success"
        :show.sync="alertSuccess"
        closeButton
    >
      Acción Realizada con Éxito.
      <CProgress
        color="info"
        :max="dismissSecs"
        :value="alertSuccess"
        height="4px"
      />
    </CAlert>


    <!-- Lista Component -->
    <div>
      <CRow>
        <CCol col>
          <CCard>
            <CCardHeader>
              <CIcon name="cil-justify-center"/>
              <strong> Seleccion</strong>
              <div class="card-header-actions">
                  <CButton color="primary" @click="nuevaSeleccion()" class="mr-1">
                    Agregar Seleccion
                  </CButton>
              </div>
            </CCardHeader>
            <CCardBody>
                  <CDataTable
                    :items="arraySelection"
                    :fields="fields"
                    column-filter
                    table-filter
                    items-per-page-select
                    :items-per-page="10"
                    hover
                    sorter
                    pagination
                  >
                    <template #estado="{item}">
                      <td>
                          <div v-if="item.status === 1">
                            Activo
                          </div>
                          <div v-else style="color:red;">
                            Inactivo
                          </div>
                      </td>
                    </template>
                    <template #acciones="{item}">
                      <td>
                        <CBadge>
                          <CButton @click="obtenerSeleccion(item.id, item.name, item.description)" color="success" shape="pill">Editar</CButton>
                          <CButton
                            color="danger"
                            variant="outline"
                            shape="pill"
                            @click="editarEstado(item.id, item.status)"
                          >
                            {{Boolean(item.status) ? 'Desactivar' : 'Activar'}}
                          </CButton>
                        </CBadge>
                      </td>
                    </template>
                  </CDataTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

    </div>



    <!-- Modal Component -->
    <CModal
        :show.sync="modalSeleccion"
        :centered="true"
        size="lg"
        color="success"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            label="Nombre"
            placeholder="Ingresar nombre de la Seleccion"
            id="modal_selection"
            v-model="modal_selection"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CTextarea
              label="Descripción"
              placeholder="..."
              rows="5"
              id="modal_description"
              v-model="modal_description"
            />
        </CCol>
      </CRow>
      <template #header>
        <h6 class="modal-title" id="modalSeleccion_h6_titulo"></h6>
        <CButtonClose @click="modalSeleccion = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="modalSeleccion = false" color="danger">Cancelar</CButton>
        <CButton @click="registrarSeleccion()" color="success" id="modalSeleccion_btn_guardar"></CButton>
      </template>
    </CModal>

  </div>
</template>

<script>


import $ from 'jquery'
import axios from 'axios'

const fields = [
  { key: 'id', label: 'ID', _style:'width:1%' },
  { key: 'name', label: 'Mercado', _style:'min-width:200px' },
  { key: 'description', label: 'Descripción' },
  { key: 'estado', label: 'Estado', _style: 'width:1%' },
  { key: 'acciones', label: 'Acciones', _style: 'width:1%', sorter: false, filter: false }
]

let cod_seleccion=0;

export default{
  name: 'Inicio',
  data(){
      return{
          dismissSecs: 5,
          alertDanger: 0,
          alertSuccess: 0,
          arraySelection:[],
          fields,
          modalSeleccion: false,
          togglePress: true,
          modal_selection: '',
          modal_description: ''
      }
  },
  methods:{
      countDownChanged (alertSuccess) {
          this.alertSuccess = alertSuccess
      },
      countDownChanged (alertDanger) {
          this.alertDanger = alertDanger
      },
      nuevaSeleccion(){
          $('#modalSeleccion_h6_titulo').html('Registrar Nueva Seleccion');
          $('#modalSeleccion_btn_guardar').html('Registrar');
          this.modal_selection = '';
          this.modal_description = '';
          cod_seleccion=0;
          this.modalSeleccion = true;
      },
      obtenerSeleccion(id, name, description){
          $('#modalSeleccion_h6_titulo').html('Editar Seleccion');
          $('#modalSeleccion_btn_guardar').html('Editar');
          this.modal_selection = name;
          this.modal_description = description;
          cod_seleccion=id;
          this.modalSeleccion = true;
      },
      listarSelection(){
          let vue=this;
          axios.get('v1/selections')
              .then( function(response){
                 vue.arraySelection=response.data['result'];
                //console.log(vue.arraySelection);
                })
              .catch(function (error) {
                  console.log(error);
              });
      },
      editarEstado(id, status){
          let me = this;
          if(parseInt(status)===0){
              axios.put('v1/selections/activated', {
                  'id': id
              }).then(function (response) {
                  if (parseInt(response.data.http_code) === 200) {
                      me.listarSelection();
                      me.alertSuccess = 5;
                  } else {
                      me.alertDanger = 5;
                  }
              }).catch(function (error) {
                  console.log(error);
              });
          }
          if(parseInt(status)===1){
              axios.put('v1/selections/disabled', {
                  'id': id
              }).then(function (response) {
                  if (parseInt(response.data.http_code) === 200) {
                      me.listarSelection();
                      me.alertSuccess = 5;
                  } else {
                      me.alertDanger = 5;
                  }
              }).catch(function (error) {
                  console.log(error);
              });
          }
      },
      validarSelection() {
          let me = this;
          me.errorSelection = false;
          $("#modal_selection").css("border", "");

          if (!(parseInt(me.modal_selection.length) > 0)){
              $("#modal_selection").css("border", "1px solid red");
              console.log("falta modal_selection");
              me.errorSelection = true;
            }

          return me.errorSelection;
      },
      registrarSeleccion() {
          if (this.validarSelection()) {
              return;
          }

          let me = this;

          if(parseInt(cod_seleccion)===0){
              axios.post('v1/selections', {
                  'name': this.modal_selection,
                  'description': this.modal_description
              }).then(function (response) {
                  if (parseInt(response.data.http_code) === 200) {
                      me.modal_selection = '';
                      me.modal_description = '';
                      me.modalSeleccion = false;
                      me.alertSuccess = 5;
                      me.listarSelection();
                  } else {
                      me.alertDanger = 5;
                  }

              }).catch(function (error) {
                  console.log(error);
              });
          }
          if(parseInt(cod_seleccion)>0){
              axios.put('v1/selections', {
                  'id': cod_seleccion,
                  'name': this.modal_selection,
                  'description': this.modal_description
              }).then(function (response) {
                  if (parseInt(response.data.http_code) === 200) {
                      me.modal_selection = '';
                      me.modal_description = '';
                      me.modalSeleccion = false;
                      me.alertSuccess = 5;
                      me.listarSelection();
                  } else {
                      me.alertDanger = 5;
                  }

              }).catch(function (error) {
                  console.log(error);
              });
          }
      }
  },
  mounted(){
      this.listarSelection();
  }
};


</script>

<style>
.post{
  border: 1px solid #D9D9D9;
  padding: 20px;
  margin-bottom: 10px;

  .btn {
  margin-bottom: 4px;
  margin-right: 6px;
}

.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
    margin-right: 8px;
  }
  .c-icon-sm {
    margin-right: 5px;
  }
  .c-icon-lg {
    margin-right: 12px;
  }
}
}
</style>